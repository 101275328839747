import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";
import ImageRoll from "../components/ImageRoll";
import { HTMLContent } from "../components/Content";
import Schedule from "../components/Schedule";
import FeaturedPosters from "../components/FeaturedPosters";

// eslint-disable-next-line
export const IndexPageTemplate = ({
                                      image,
                                      title,
                                      heading,
                                      subheading,
                                      description,
                                      posters
                                  }) => {
    const heroImage = getImage(image) || image;
    return (
        <div>
            <FullWidthImage img={heroImage} title={title} subheading={subheading} />
            <section className="section section--gradient">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12">
                            <div className="content">
                                <div className="columns">
                                    <div className="column is-10 is-offset-1">
                                        <h3 className="has-text-weight-semibold is-size-2">
                                            {heading}
                                        </h3>
                                        <HTMLContent content={description} />
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                                        Latest Posters
                                    </h3>
                                    <FeaturedPosters posters={posters} />
                                </div>
                                <div className="column is-12">
                                    <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                                        Club News
                                    </h3>
                                    <BlogRoll limit={3} />
                                    <div className="column is-12 has-text-centered">
                                        <Link className="btn" to="/blog">
                                            Read more
                                        </Link>
                                    </div>
                                </div>
                                <div className="column is-12 has-text-centered">
                                    <Schedule />
                                </div>
                                <div className="column is-12">
                                    <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                                        Gallery
                                    </h3>
                                    <ImageRoll limit={8} />
                                    <div className="column is-12 has-text-centered">
                                        <Link className="btn" to="/gallery">
                                            View more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    html: PropTypes.string
};

const IndexPage = ({ data }) => {
    const { frontmatter, html } = data.markdownRemark;
    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                description={html}
                posters={frontmatter.featuredposters}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            html: PropTypes.string,
            frontmatter: PropTypes.object
        })
    })
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            html
            frontmatter {
                title
                image {
                    childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                }
                featuredposters {
                    image {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                        }
                    }
                }
                heading
                subheading
            }
        }
    }
`;
