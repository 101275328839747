import React from 'react'
import scheduleData from "../data/schedule.yml"


export default function Schedule() {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return (
        <div className="schedule has-background-primary-light is-inline-block has-text-left" id="schedule">
            {days.map((day => {
                const todaysEvents = scheduleData.schedule.filter(obj => {
                    return obj.day === day
                });
                return (todaysEvents.length > 0 && <div className="schedule__day pt-3 px-3">
                        <h3 className="schedule__day-title">{day}</h3>
                        {todaysEvents.map((event => {
                            return (
                                <div className="mb-3">
                                    <p className="has-text-weight-bold mb-0">{event.time} - {event.title}</p>
                                    <p>{event.description}</p>
                                </div>
                            );
                        }))}</div>
                )
            }))}
        </div>
    );
}