import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const FeaturedPosters = (props) => {
    const posters = props.posters;

    return (<div className="featured-posters columns">
        {posters.map((poster, index) => {
            const image = getImage(poster.image) || poster;
            return <div key={index} className="featured-posters__poster column"><div className="box"><GatsbyImage alt='' image={image} /></div></div>
        })}
    </div>);
}

FeaturedPosters.propTypes = {
    posters: PropTypes.array
}


export default FeaturedPosters

